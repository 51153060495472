export const environment = {
  production: true,
  hmr: false,
  mediaApiEndpoint: 'https://dmcmediaservice-staging.azurewebsites.net/api/v1.0/',
  mediaApiEndpoint_V2: 'https://dmcmediaservice-staging.azurewebsites.net/api/v2.0/',
  adminApiEndpoint: 'https://dmcmediaservice-staging.azurewebsites.net/api/v1.0/',
  dmcUrl: 'https://staging.dmcpro.net',
  dmcApiurl:'http://localhost:4065/api',
  redirectUri:'http://localhost:4200/login/',
  dashboardSeeMoreSize: 75
};

